/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import InfiniteAjaxScroll from '@webcreate/infinite-ajax-scroll';


var bar = new ProgressBar.Line('.preloader-bar', {
    strokeWidth: 4,
    easing: 'easeInOut',
    duration: 1000,
    color: '#fff',
    trailWidth: 1,
    svgStyle: { width: '100%', height: '100%' },
    from: { color: '#fff' },
    to: { color: '#152E53' },
    step: (state, bar) => {
        bar.path.setAttribute('stroke', state.color);
    }
});

bar.animate(0.5); // Number from 0.0 to 1.0

$(function() {

    if ($('.ias-container').length > 0) {
        let ias = new InfiniteAjaxScroll('.ias-container', {
            item: '.blog-item',
            next: '.next',
            pagination: '.pagination'
        });
    }

    setClock('#clock1');
    setClock('#clock2');
    setInterval(function() {
        setClock('#clock1');
        setClock('#clock2');
    }, 60000);

    $('.three-item-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [{
            breakpoint: 991,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            }
        }]
    });


    $('#mainnav').on('show.bs.collapse', function() {
        $(this).parents('body').addClass('disable-scroll');
    })
    $('#mainnav').on('hide.bs.collapse', function() {
        $(this).parents('body').removeClass('disable-scroll');
    })

    $('.dropdown-toggle').attr('data-toggle', 'dropdown');

    $(".menubox-toggler").click(function() {
        $('#menubox').toggleClass('open');
        $("body").toggleClass("disable-scroll");
    });

    $(document).keyup(function(e) {
        if (e.keyCode == 27) { // escape key maps to keycode `27`
            $('.navbar').removeClass('open')
        }
    });

    $('.product-item').hover(function() {
        $(this).children('.product-item-title').addClass('product-item-detail');
    }, function() {
        $(this).children('.product-item-title').removeClass('product-item-detail');
    });
});

$(window).on('load', function() {
    bar.animate(1.0, {
        duration: 500
    }, function() {
        $('.preloader').fadeOut(100);
    });
});

function setClock(id) {
    if ($(id).data('timezone') == undefined) {
        var timezone = 'UTC';
    } else {
        var timezone = $(id).data('timezone');
    }
    var zTime = new Date(new Date().toLocaleString("en-US", { timeZone: timezone }));
    var hours = zTime.getHours();
    var mins = zTime.getMinutes();
    var hdegree = hours * 30 + (mins / 2);
    var hrotate = "rotate(" + hdegree + "deg)";
    var mdegree = mins * 6;
    var mrotate = "rotate(" + mdegree + "deg)";

    $(id + ".clock .digital").html(zTime.toLocaleString("en-US", { hour: 'numeric', minute: '2-digit' }));
    $(id + ".clock .hour").css({ "-moz-transform": hrotate, "-webkit-transform": hrotate });
    $(id + ".clock .min").css({ "-moz-transform": mrotate, "-webkit-transform": mrotate });
}
